<template>
  <div :key="componentData.isNieuw" class="gap-4 max-w-6xl xl:max-w-full mx-auto">
    <div class="card shadow flex flex-col gap-4 items-start">
      <UIInput
        v-model="form.titel"
        required
        label="Titel"
        class="w-full max-w-2xl"
        placeholder="Titel ..."
      />
      <UIInputDatePicker
        v-model:date="form.datum"
        v-model:time="form.tijd"
        required
        label="Datum en tijd"
        class="text-left"
        timepicker
      />
      <UIInputLabel class="block w-full">
        Chauffeurs
      </UIInputLabel>
      <div v-for="(id, index) in form.user_ids" :key="`${id}-${index}`" class="-mt-2 w-full max-w-sm flex flex-row justify-center">
        <UISelectChauffeur v-model="form.user_ids[index]" active class="flex-1" />
        <button :disabled="isChauffeursDisabled(form.user_ids[index])" class="inline-flex justify-center items-center p-2 group" @click="form.user_ids.splice(index, 1)">
          <i class="fas fa-times" :class="{ 'group-hover:text-red-500': !isChauffeursDisabled(form.user_ids[index]) }"></i>
        </button>
      </div>
      <button class="m-0" @click="form.user_ids.push(null)">
        + Chauffeur toevoegen
      </button>
      <GoogleTussenstops
        v-model="form.adressen"
        class="flex flex-col gap-2 max-w-2xl"
        :disabled="disabled"
        label="Adressen"
        placeholder="Adres"
      />
      <UIInput
        v-model="form.opmerking"
        textarea
        label="Opmerking"
        class="w-full max-w-3xl"
        rows="4"
        placeholder="Opmerking ..."
      />
      <button
        type="submit"
        :disabled="!form.titel || !form.datum"
        class="btn success"
        @click.prevent.stop="onSubmit()"
      >
        Opslaan
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, unref, watch } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import GoogleTussenstops from '@/components/Google/Tussenstops.vue'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIInput from '@/components/UI/Input/Input'
import UIInputLabel from '@/components/UI/Input/Label'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'

import { dateString, timeString } from '@/functions/formatDate'

const props = defineProps({
  disabled: Boolean,
  modelValue: Object,
})

const emit = defineEmits(['update:modelValue', 'submit'])
const store = useStore()
const date = new Date(props.modelValue?.datum || props.modelValue?.date || (Date.now() + 86400000))

const generateForm = modelValue => {
  const obj = {
    id: modelValue?.id || null,
    titel: modelValue?.titel || '',
    user_ids: modelValue?.user_ids || modelValue?.user_id || store.state.profile?.id,
    adressen: (modelValue?.adressen || []).map(el => ({ adres: el.adres || el })).filter(el => !!el),
    datum: dateString(modelValue?.datum || modelValue?.date || date),
    tijd: timeString(modelValue?.tijd || modelValue?.datum || modelValue?.date || date),
    opmerking: modelValue?.opmerking || '',
  }
  if (!Array.isArray(obj.user_ids)) {
    obj.user_ids = [obj.user_ids]
  }
  return obj
}

const form = ref(generateForm(unref(props.modelValue)))
watch(form, val => emit('update:modelValue', val))
watch(() => props.modelValue, val => {
  if (val && JSON.stringify(val) !== JSON.stringify(form.value)) {
    form.value = generateForm(val)
  }
})
onMounted(() => emit('update:modelValue', form.value))

const isChauffeursDisabled = computed(() => user_id => (user_id && form.value.user_ids?.filter(el => !!el).length <= 1) || props.disabled)

const componentData = ref({
  isNieuw: true,
})

const onSubmit = () => {
  const submitData = clone(form.value)
  submitData.user_ids = submitData.user_ids.filter(el => !!el)
  submitData.adressen = submitData.adressen.map(el => el.adres || el).filter(el => !!el)
  emit('submit', submitData)
}
</script>
