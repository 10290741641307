<template>
  <div>
    <h1 class="mb-4">
      Afspraak #{{ apiDataGet.afspraak_id }} bewerken
    </h1>
    <span v-if="loading">Loading ...</span>
    <FormAfspraak
      v-else
      v-model="form"
      :disabled="api.loading.value"
      @submit="onSubmit"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import FormAfspraak from '@/components/Form/Afspraak.vue'

import { dateString } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'
import useRedirectSafe from '@/hooks/useRedirectSafe'

const route = useRoute()
const apiDataGet = computed(() => ({ afspraak_id: route.params.afspraak_id }))

const api = useApi()
const { loading, data: form } = useGetApi('AFSPRAAK_BEWERKEN_INFO', apiDataGet, { watch: true })

const onSubmit = data => {
  api('AFSPRAAK_EDIT', data).then(() => {
    // if (route.query.from) {
    //   useRedirectSafe(decodeURIComponent(route.query.from))
    //   return
    // }
    const date = dateString(data.datum) || undefined
    useRedirectSafe({ name: 'Kalender', query: { date } })
  })
}
</script>
