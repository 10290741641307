<template>
  <div>
    <h1 class="mb-4">
      Nieuwe afspraak inboeken
    </h1>
    <FormAfspraak v-model="form" :disabled="api.loading.value" @submit="onSubmit" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import clone from 'just-clone'

import FormAfspraak from '@/components/Form/Afspraak.vue'

import useApi from '@/hooks/useApi'
import useRouteQueryDate from '@/hooks/useRouteQueryDate'

const api = useApi()

const dateFromQuery = computed(() => useRouteQueryDate('date')?.value || '')
const defaultForm = () => clone({
  datum: dateFromQuery.value,
})

const form = ref(clone(defaultForm()))

const onSubmit = data => {
  api('AFSPRAAK_CREATE', data).then(() => {
    const datum = String(form.value.datum || defaultForm.datum)
    const tijd = String(form.value.tijd || defaultForm.tijd || '')
    form.value = clone(defaultForm())
    form.value.datum = datum
    form.value.tijd = tijd
  })
}
</script>
